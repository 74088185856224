import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout/Layout';
import urls from 'config/urls';
import fullScreen from 'styles/mixins/fullScreen';
import Button from 'components/Button';

const StyledWrapper = styled.div`
  ${fullScreen};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.color.black};
  h1, p {
    padding: 1rem 0;
  }
  h1 {
    font-weight: 700;
    letter-spacing: 1rem;
    font-size: 3.5rem;
  }
  p {
    font-weight: 700;
    letter-spacing: 1rem;
    font-size: 3rem;
    padding-bottom: 5rem;
    span {
      color: ${({ theme }) => theme.color.grayLighter};
    }
  }
`;

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <StyledWrapper>
      <h1>NOT FOUND</h1>
      <p>
        40
        <span>4</span>
      </p>
      <Button component="link" to={urls.HOME} color="dark">Back to home</Button>
    </StyledWrapper>
  </Layout>
);

export default NotFoundPage;
